import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import App from 'App'
import { colors, fontSizes } from 'theme'
import { toHumanReadable } from 'utils/date'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Footer from 'components/Footer'
import Button from 'components/Button'
import LocalImage from 'components/LocalImage'
import DropdownMenu from 'components/DropdownMenu'
import GetStartedComponent from 'components/GetStartedComponent'
import facebookIcon from 'static/svg/facebook_icon.svg'
import linkedinIcon from 'static/svg/linkedin_icon.svg'
import twitterIcon from 'static/svg/twitter_icon.svg'

const HeroImg = styled(Img)`
  max-width: 1080px;
  width: 100%;
  height: auto;
  object-fit: contain;
  pointer-events: none;
`

const AricleBody = styled.div`
  max-width: 720px;
  figure {
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  font-size: ${fontSizes.xl};
  a {
    color: ${colors.primary};
  }
`

const ButtonLink = styled(Button.Secondary)`
  display: flex;
  text-decoration: none;
  border: 1px solid ${colors.white};
  &:hover {
    background-color: #ffffff;
    border: 1px solid rgba(253, 83, 89, 0.1);
  }
`

const SocialColumnWrapper = styled(Box)`
  grid-column: wide-start/wide-end;
  margin: 0 !important;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`

const SocialWrapper = styled(Flex)`
  position: sticky;
  top: calc(120px + 4vw);
`

const StyledSocialImageWrapper = styled(Flex)`
  cursor: pointer;
  transition: filter 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 10px 16px;
  :not(:hover) {
    filter: saturate(20%) contrast(100%);
  }
`

const SOCIALS = [
  {
    target: 'share-twitter',
    icon: twitterIcon,
    link: 'https://twitter.com/share?url=',
    label: 'Twitter',
  },
  {
    target: 'pop',
    icon: facebookIcon,
    link: 'https://www.facebook.com/share.php?u=',
    label: 'Facebook',
  },
  {
    target: 'share-linkedin',
    icon: linkedinIcon,
    link: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    label: 'LinkedIn',
  },
]

const PostTemplate = (data) => {
  // console.log(data)

  const {
    data: { wordpressPost: post },
    location: { pathname, href: postLink },
  } = data

  const {
    title,
    content,
    excerpt,
    date,
    author: { name: authorName = 'Agency Vista' } = {},
    categories = [],
    yoast_meta: { yoast_wpseo_title: seoTitle, yoast_wpseo_metadesc: seoDescription } = {},
  } = post
  const {
    title: featureImageTitle,
    alt_text,
    caption,
    source_url: featuredImageUrl,
    localFile: { childImageSharp: { fluid: image } = {} } = {},
  } = post.featured_media || {}
  return (
    <App>
      <SEO
        title={seoTitle || title}
        description={seoDescription || excerpt}
        author={authorName}
        image={featuredImageUrl}
        type="article"
        path={pathname}
      />
      <Flex mt="l" px="m" flexDirection="column" alignItems="center">
        {image && <HeroImg fluid={image} />}
        <Text mt="m" color="secondaryText" fontSize="xs">
          {toHumanReadable(date)} - {authorName}
        </Text>
        {categories.length > 0 && (
          <Flex mt="s" width="100%" px="m" justifyContent="center" flexWrap="wrap">
            {categories.map(({ slug, name }) => (
              <ButtonLink as="a" href={`/insights/${slug}`} key={slug}>
                <Text py="s">{name}</Text>
              </ButtonLink>
            ))}
          </Flex>
        )}
        <Flex mt="xl" flexDirection="column" alignItems="center" width="100%">
          <Flex flexDirection="column" alignItems="center" position="relative" maxWidth="1024px" width="100%">
            <SocialColumnWrapper display={{ mobile: 'none', tablet: 'block' }}>
              <SocialWrapper flexDirection="column" alignItems="center">
                {SOCIALS.map(({ icon, link, target, label }) => {
                  let updatedLink = postLink
                  if (target === 'share-twitter') {
                    updatedLink = `${postLink}&text=${title}`
                  }
                  return (
                    <StyledSocialImageWrapper
                      onClick={() => {
                        window.open(`${link}${updatedLink}`, target, 'width=760,height=570')
                      }}
                    >
                      <LocalImage source={icon} width="32px" height="32px" alt={`Share on ${label}`} />
                    </StyledSocialImageWrapper>
                  )
                })}
              </SocialWrapper>
            </SocialColumnWrapper>
            <AricleBody mt="m">
              <H1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </AricleBody>
          </Flex>
        </Flex>
        <Box mt="xxl">
          <GetStartedComponent />
        </Box>
      </Flex>
      <Box mt="xl">
        <Footer />
      </Box>
    </App>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate

export const query = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date
      author {
        name
      }
      categories {
        slug
        name
      }
      featured_media {
        title
        alt_text
        caption
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
